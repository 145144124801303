#google-map {
  width: 100%;
  height: 100%;
}

.info-site-name {
  font-size: 19px;
  font-weight: 400;
  color: #202124;
  letter-spacing: 0.25px;
  margin: 10px 0;
}

#link-to-alarms {
  letter-spacing: 0.25px;
  font-size: 16px;
}

.gm-style-iw {
  text-align: center;
}

#map-main-div {
  height: 100vh;
}

#legend {
  font-family: Arial, sans-serif;
  background: #fff;
  padding: 10px;
  margin: 10px;
  border: 3px solid #000;
  font-size: 16px;
}

#legend h3 {
  margin-top: 0;
  font-weight: bolder;
  margin-bottom: 10px;
}

#legend img {
  vertical-align: middle;
}
