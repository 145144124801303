#left-nav {
  background-color: #f8f9fa;
  width: 17vw;
  float: left;
  font-size: 21px;
  font-family: "Roboto", "Arial", "Helvetica", "Neue", sans-serif !important;
  padding-left: 40px;

  /*temporary until add Dashboard back******************************************************************/
  padding-top: 60px;
}

.nav-link,
.nav-link:visited {
  text-decoration: none;
  letter-spacing: 0.25px;
  font-weight: 500;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.54);
  padding-left: 15px;
}

.nav-link:hover {
  font-size: 19px;
  color: rgba(0, 0, 0, 0.87);
}

.nav-selected {
  color: #1a73e8 !important;
}

#left-nav li {
  height: 40px;
}

#left-nav li:nth-child(1) {
  margin-top: 43px;
}

/*#left-nav li:nth-child(5)
#left-nav li:nth-child(3)  */
#left-nav li:nth-child(2){
  margin-bottom: 40px;
}

i {
  width: 25px;
}

@media screen and (max-width: 1580px) {
  /* .nav-link {
    color: red !important;
  } */
}

@media screen and (max-width: 1250px) {
  /* .nav-link {
    color: red !important;
  } */
}

@media screen and (max-width: 1100px) {
  /* .nav-link {
    color: purple !important;
  } */
  #left-nav {
    width: 20vw;
    padding-left: 15px;
  }
}

@media screen and (max-width: 650px) {
  /* .nav-link {
    color: purple !important;
  } */
  #left-nav {
    width: 26vw;
  }
}
