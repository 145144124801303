#filter-string-wrapper {
    line-height: 1.5;
    margin: 75px 0 0 17px;
    /* margin-top: 75px;
    margin-left: 17px;
    margin-right: 170px; */
    font-size: 16px;
}

.filter-string {
    max-width: 100%;
    margin: 0;
    padding: 0;
    letter-spacing: 0.3px;
    opacity: 0.75;
    margin-block-start: 0;
    margin-block-end: 0;
}

#filter-reset {
    color: #1a73e8;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 15px;
}

@media screen and (max-width: 835px) {
    #filter-string-wrapper {
        display: none;
    }
}