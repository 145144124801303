.last-text-in-detail {
  padding-top: 16px;
  display: inline-block;
}

.last-detail-item {
  padding-bottom: 16px;
}

.shadow-box {
  border-bottom: 1px solid #f8f8f8;
  width: 700px;
}

#last-alarm-detail-section {
  margin-bottom: 100px;
}

.alarm-detail-section {
  width: 100%;
  border: solid 2px #dadce0;
  padding: 15px 40px 29px 40px;
  border-radius: 9px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.07);
  background-color: #fff;
  /* color: #202124; */
  /* color: 0, 0, 0, 0.87; */
  color: #5f6368;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 45px;
  letter-spacing: 0.15px;
}

.alarm-detail-section .heading {
  font-size: 20px;
  margin-bottom: 10px;
}

.alarm-detail-section .detail {
  font-weight: 900;
  margin-right: 10px;
  /* color: #202124; */
}

#description-label,
#location-notes-label,
#contact-notes-label,
#access-notes-label {
  display: inline-block;
  width: 104px;
  vertical-align: top;
}

#description-label {
  width: 104px;
}

#location-notes-label {
  width: 134px;
}

#contact-notes-label {
  width: 126px;
}

#access-notes-label {
  width: 122px;
}

.wrapping-text {
  display: inline-block;
}

.detail-item {
  margin-bottom: -22px;
}

.detail-item p {
  display: inline-block;
}

.note-card .source {
  display: inline-block;
  margin-right: 15px;
  width: 200px;
}

.note-card .content {
  display: inline-block;
  white-space: wrap;
  vertical-align: top;
  margin-top: 17px;
  width: calc(100% - 216px);
}

.note-card .source .author {
  font-weight: 900;
}

p.note-datetime {
  margin-top: -15px;
  font-size: 15px;
  color: #989898;
}

.content span {
  white-space: wrap;
  vertical-align: top;
}

.alarm-note-form {
  width: 100%;
  margin: 17px auto 14px 0;
}

.error-message {
  color: red;
}

#note-label {
  width: 200px;
  margin-right: 15px;
  display: inline-block;
  vertical-align: top;
}

.alarm-note-input {
  height: 120px;
  border: 3px solid #cccccc;
  padding: 5px;
  width: calc(100% - 84px);
}

.submit-alarm-note {
  padding: 6px;
  background-color: #1a73e8;
  color: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 1.5px #888888;
  font-size: 18px;
  width: 74px;
  margin-left: 215px;
  display: block;
  margin-top: 10px;
}

a,
a:visited {
  color: #1a73e8;
}

.fa-map-marker-alt {
  margin-left: 7px;
  margin-right: -8px;
}

@media screen and (max-width: 1540px) {
  .submit-alarm-note {
    margin-left: 0px;
  }
}
