.red {
  color: red;
}

.green {
  color: green;
}

.blue {
  color: #1a73e8;
}

.center {
  text-align: center;
}

.auth-input {
  display: block;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #dadce0;
  outline: none;
  background-color: #fff;
  font-size: 16px;
}

.auth-card {
  width: 450px;
  margin: 85px auto 0 auto;
  border: solid 2px #dadce0;
  padding: 15px;
  border-radius: 9px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.07);
  background-color: #fff;
  color: 0, 0, 0, 0.87;
  font-size: 17px;
}

.auth-form-header {
  font-size: 25px;
  color: #1663ab;
  margin-bottom: 20px;
}

#reset-password-text {
    color: #5f6368;
    margin-bottom: 15px;
}

.auth-form-footer {
  text-align: center;
}

#no-acct {
  color: #000;
}

#get-password-form,
#no-acct {
  padding-top: 10px;
}

#get-password-form,
#get-signup-form {
  color: #1a73e8;
}

.form-logo {
  width: 30px;
  margin: 1px 7px 0px 5px;
}

.auth-submit {
  padding: 6px;
  background-color: #1a73e8;
  color: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 1.5px #888888;
  margin-bottom: 15px;
  font-size: 18px;
}

.auth-submit {
  width: 100%;
}

.auth-submit:hover {
  background-color: #174ea6;
}
