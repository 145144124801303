header {
  font-weight: 300;
  font-size: 30px;
  color: #202124;
  padding-top: 35px;
  padding-bottom: 35px;
  display: inline-block;
  padding-left: 20px;
}

.header-right {
  float: right;
  padding-top: 28px;
  font-size: 18px;
  color: #5f6368;
}

.fa-pen,
.fa-plus,
.fa-trash-alt {
  color: #1a73e8;
  font-size: 18px;
  opacity: 0.9;
  vertical-align: middle;
}

.fa-pen,
.fa-plus {
  margin-left: 15px;
}

.fa-pen {
  font-size: 16px;
}
