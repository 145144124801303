#search-area {
    float: right;
    margin-top: 10px;
}

#search-input-div {
    position: relative;
    border: solid 2px #dadce0;
    width: 450px;
    height: 35px;
    border-radius: 5px;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.04);
    margin-bottom: -5px;
}

#search, #search-icon {
    position: absolute;
    left: 0;
}

#search-icon {
    z-index: 2;
    padding-left: 5px;
    top: 6px;
    opacity: 0.7;
}

#search {
    padding-left: 25px;
    width: 100%;
    height: 100%;
    border: none;
    font-size: 15px;
    color: #777777;
}

/* .alarm-date-input::placeholder, */
#search::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(0,0,0,0.3);
}

.fa-search {
    color: rgba(0,0,0,0.3);
}

#advanced-search {
    float: right;
    color: #1a73e8;
    margin-bottom: 0;
}

#clear-filter-arrow-icon {
    font-size: 14px;
}

#advanced-search-icon {
    font-size: 15px;
    margin-right: -10px;
}

#download {
    color: #1a73e8;
    float: right;
    margin-right: 30px;
}

#download-icon {
    font-size: 14px;
    margin-right: -10px;
}

.advanced-search-bottom-margin {
    margin-bottom:15px;
}

@media screen and (max-width: 680px) {
    #search-input-div {
        width: 345px;
    }
}

@media screen and (max-width: 600px) {
    #search-input-div {
        width: 270px;
    }
}


