#notification-form .checkbox {
  margin-right: 10px;
}

#notification-submit,
#notification-shadow-box {
  width: 326px;
}

#notification-phone-input {
  width: 253px;
  border: solid 2px #dadce0;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.04);
  padding: 7px;
  font-size: 16px;
  color: #5f6368;
}

#notification-phone-input::placeholder {
  color: #5f6368;
  opacity: 0.5;
}

#notification-form select {
  width: 250px;
  border: solid 2px #dadce0;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.04);
  padding: 7px;
  font-size: 16px;
  color: #5f6368;
}

@media screen and (max-width: 1100px) {
  #notification-submit,
  #notification-shadow-box {
    width: 253px;
  }
}
