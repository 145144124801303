@keyframes heartbeat {
  0% {
    transform: scale(0.95);
  }
  20% {
    transform: scale(1.05);
  }
  40% {
    transform: scale(0.95);
  }
  60% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.95);
  }
}

/*FILE*/

#file-table {
  width: 100%;
  table-layout: fixed;
}

#file-table-name-col {
  width: 40.5%;
}

#file-table-desc-col {
  width: 40%;
}


#file-table-size-col {
  width: 10.5%;
}

#file-table-edit-icon-col,
#file-table-delete-icon-col {
  width: 4%;
  text-align: center;
}

#file-table td {
  vertical-align: top;
}

#file-table-name,
#file-table-desc,
#file-table-size
 {
  overflow: auto;
  display: inline-block;
  max-width: 100%;
  padding: 0;
  margin: 0;
  padding-bottom: 10px;

}

#edit-file-icon,
#delete-file-icon {
  padding: 0;
  margin: 0;
}

.file-desc-submit {
  width: 700px;
}

.file-desc-textarea {
  width: 700px;
  height: 100px;
  border: solid 2px #dadce0;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.04);
  padding: 7px;
  font-size: 16px;
  color: #5f6368;
}

.file-desc-textarea::placeholder {
  color: #5f6368;
  opacity: 0.5;
}

.file-desc-label {
  display: block;
}

#select-file-wrapper {
  width: 700px;
  position: relative;
}

#select-file-label {
  width: 200px;
  border: solid 2px #dadce0;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.04);
  padding: 7px;
  font-size: 16px;
  z-index: 2;
  margin-right: 10px;
  vertical-align: top;
  background-color: #f8f9fa;
}

#select-file-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

#selected-file-value {
  width: 584px;
  overflow: auto;
  padding-bottom: 5px;
  display: inline-block;
}

@media screen and (max-width: 1100px) {
  .file-desc-submit {
    width: 253px;
  }
}



/*FILE END*/

.reset-heartbeat-info {
  font-style: italic;
  font-size: 15px;
  color: #1663ab
}

.dot {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: inline-block;
  font-family: Georgia, serif;
  color: 	#fff;
  background-color: rgba(0, 0, 0, 0.54);
  opacity: 0.8;
  margin-left: 5px;
  text-align: center;
  font-size: 8px;
  position: relative;
  bottom: 3px;
  padding-top: 1px;
}

.item-to-delete-description {
  margin: 0;
}

.delete-item-div {
  margin-top: 175px;
}

.active-heartbeat {
  color: blue;
  animation: heartbeat 1s infinite;
}

.lost-heartbeat {
  color: red;
}

.no-heartbeat {
  color: grey;
}

/* site detail */
#advanced-alarm-search-form .detail,
#site-form .detail,
#system-form .detail,
#user-form .detail {
  display: inline-block;
}

#advanced-alarm-search-form select,
#site-form input,
#site-form textarea,
#site-form select,
#system-form input,
#system-form textarea,
#system-form select,
#user-form select,
#user-form input {
  width: 700px;
  border: solid 2px #dadce0;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.04);
  padding: 7px;
  font-size: 16px;
  color: #5f6368;
}

.site-form-textarea,
.system-form-textarea {
  height: 100px;
}

.alarm-detail-section {
  width: 100%;
  border: solid 2px #dadce0;
  padding: 15px;
  border-radius: 9px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.07);
  background-color: #fff;
  color: #5f6368;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 24px;
  letter-spacing: 0.15px;
}

.auth-input {
  display: block;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #dadce0;
  outline: none;
  background-color: #fff;
  font-size: 16px;
}

.details-submit {
  padding: 6px;
  background-color: #1a73e8;
  color: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 1.5px #888888;
  font-size: 18px;
  width: 700px;
  display: block;
  margin-top: 10px;
}

#logout-msg-btn {
  display: inline;
  width: 170px;
  margin-right: 20px;
  margin-bottom: 5px;
}

#eight-hr-timer-label {
  margin-left: 5px;
}

.details-form-footer {
  width: 700px;
  text-align: center;
  margin-top: 5px;
  color: #1a73e8;
  display: block;
}

#file-desc-name {
  padding-top: 10px;
  width: 100%;
  margin-bottom: -10px;
}

#file-desc-name .detail {
  width: 52px;
  display: inline-block;
  vertical-align: top;
}

#file-desc-name-value {
  width: 638px;
  overflow: auto;
  display: inline-block;
  padding-bottom: 5px;
}

@media screen and (max-width: 1250px) {
  #advanced-alarm-search-form select,
  #site-form input,
  #site-form textarea,
  #site-form select,
  #system-form input,
  #system-form textarea,
  #system-form select,
  #user-form select,
  #user-form input,
  .file-desc-textarea,
  .file-desc-submit,
  #file-desc-name,
  #file-desc-name-value,
  .details-submit,
  #select-file-wrapper,
  .details-form-footer {
    width: 100%;
    display: block;
  }

  #selected-file-value {
    margin-top: 5px;
    max-width: 100%;
  }

}

@media screen and (max-width: 750px) {
  #logout-msg-btn {
    display: block;
  }
}

@media screen and (max-width: 550px) {
  #logout-msg-btn {
    width: 100%;
  }
}