.main {
  width: 81.5vw;
  min-height: 100vh;
  float: right;
  padding-right: 40px;
  padding-bottom: 200px;
}

.main-detail {
  width: 64.5vw;
  margin-right: 17vw;
}

/*overflow: auto; removes scrollbar from IE until needed*/
p,
span,
textarea,
input {
  max-width: 700px;
}

.bold {
  font-weight: bold;
}

@media screen and (max-width: 1100px) {
  .main {
    width: 78.5vw;
  }

  .main-detail {
    width: 77.5vw;
    margin-right: 1vw;
  }
}

@media screen and (max-width: 650px) {
  .main {
    width: 72.5vw;
  }

  .main-detail {
    width: 71.5vw;
    margin-right: 1vw;
  }
}
