#advanced-alarm-search-form {
    position: absolute;
    z-index: 10;
    top: 210px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 58vw;
    max-width: 755px;
    padding: 25px;
}

#alarm-date-from-input {
    margin-right: 30px;
}

#alarm-date-to-input,
#alarm-date-from-input {
    border: solid 2px #dadce0;
    border-radius: 5px;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.04);
    padding: 7px;
    font-size: 16px;
    color: #5f6368;
    width: 200px;
}

#advanced-alarm-search-form .checkbox {
    margin-right: 10px;
}

.disabled-system {
    opacity: 0.7;
}

@media screen and (max-width: 1250px) {

    #advanced-alarm-search-form select {
        width: 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: 975px) {

    #alarm-date-to-input {
        margin-bottom: 17px;
    }

    #alarm-date-to-input,
    #alarm-date-from-input {
        display: block;
    }

}