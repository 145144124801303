
.table-wrapper {
    width: 100%;
    border: solid 2px #dadce0;
    padding: 25px;
    border-radius: 9px;
    box-shadow: 2px 2px 3px 0 rgba(0,0,0, .07);
    background-color: #fff;
}

table {
    width: 100%;
    color: 0, 0, 0, 0.87;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.15;
}

#add-file-icon {
    margin-left: 5px;
    margin-top: -3px;
    font-size: 16px;
}

table th {
    letter-spacing: 0.2px;
    text-align: left;
    font-weight: 700;
}

/* table th:visited { */
    /* color: #202124; */
    /* color: blue;
} */

/* table th:active {
    color: red;
} */


table th,
table td {
  padding: 10px 15px;
  border-bottom: 1px solid rgba(0,0,0,0.08);
}

table tbody tr:hover {
    background-color: rgb(246, 247, 249);
}
  