/*allows border and padding to be included in elements' width and height
so doesn't change when add border/padding*/
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/*step 1 of 3 to make sticky footer*/
html {
  position: relative;
  min-height: 100%;
}

/*margin 65 (height of footer) is step 2 of 3 to make sticky footer*/
body {
  margin: 0 0 65px 0;
  color: #777777;
  background-color: #f8f9fa;
  text-rendering: optimizeLegibility;
}

li {
  list-style-type: none;
}

html,
body {
  font-family: "Open Sans", "Roboto", "Arial", "Helvetica", "Neue", sans-serif !important;
}

table {
  font-family: "Roboto", "Arial", "Helvetica", "Neue", sans-serif !important;
}

/* Optional: Makes the sample page fill the window. */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

li a {
  text-decoration: underline;
  color: #0288d1;
}

/*position absolute/bottom 0/width 100% is step 3 of 3 to make sticky footer*/
footer {
  background-color: #189ad3;
  height: 65px;
  text-align: center;
  color: #ffffff;
  padding-top: 25px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
